import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('This field is required'),
  address1: Yup.string().required('This field is required'),
  city: Yup.string().required('This field is required'),
  state: Yup.string().required('This field is required'),
  zipcode: Yup.string().required('This field is required'),
  which: Yup.string().required('This field is required'),
  how: Yup.string().required('This field is required'),
});
