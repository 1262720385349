import { useRef, useState } from 'react';
import './App.css';
import catalogImg from './CatalogFlip.png';
import {validationSchema} from './schema';
import { Formik } from 'formik';
import Select from 'react-select';
import { states, interests, locations } from './constants';
import ReCAPTCHA from 'react-google-recaptcha';

function App() {
  const [msg] = useState('');
  const [interestCheck, setInterestCheck] = useState(interests.map(i => ({checked: false, label: i})));
  const captchaRef = useRef(null);
  
  const onSubmit = values => {
    // const token = captchaRef.current.getValue();
    captchaRef.current.reset();
  }
  return (
    <div className="request-container">
      <h1 className='request-title'>Free Fence Guide & Catalog</h1>
      <p className='request-description'>Get 80 Pages of products, installation tips, and everything you need to make your fence project easier than ever.<br/>Includes step-by-step instructions for building high-tensile fence, electric rope fence, and much more.</p>
      <a href='https://kencove.com/fence/Assets/catalog' target='_blank' rel='noreferrer'>
        <img src={catalogImg} alt="catalog" />
      </a>
      <a href='https://kencove.com/fence/Assets/catalog' target='_blank' rel='noreferrer' className='catalog-link'>
        Browse the virtual catalog here
      </a>
      <p className='request-description'>Virtual Catalog is updated at time of catalog distribution. Prices valid at print distribution, please see web products for most up to date information.</p>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          company: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipcode: '',
          email: '',
          phone: '',
          interests: [],
          how: '',
          which: '',
          comments: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({values, handleChange, handleSubmit, errors, touched, setFieldValue}) => (
          <form onSubmit={handleSubmit}>
            {msg && <div className='error-msg'>{'msg'}</div>}
            <div className='request-form-item'>
              <div className='request-form-side'>
                <div className='request-form-input-label'>First Name<span>*</span></div>
                <input value={values.firstName} name='firstName' onChange={handleChange} className="request-form-input" />
                {errors.firstName && touched.firstName && <span className='request-error'>{errors.firstName}</span>}
              </div>
              <div className='request-form-side'>
                <div className='request-form-input-label'>Last Name</div>
                <input value={values.lastName} name='lastName' onChange={handleChange} className="request-form-input" />
              </div>
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>Farm or Company</div>
              <input value={values.company} name='company' onChange={handleChange} className="request-form-input" />
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>Address Line 1 <span>*</span></div>
              <input value={values.address1} name='address1' onChange={handleChange} className="request-form-input" />
              {errors.address1 && touched.address1 && <span className='request-error'>{errors.address1}</span>}
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>Address Line 2</div>
              <input value={values.address2} name='address2' onChange={handleChange} className="request-form-input" />
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>City <span>*</span></div>
              <input value={values.city} name='city' onChange={handleChange} className="request-form-input" />
              {errors.city && touched.city && <span className='request-error'>{errors.city}</span>}
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>State <span>*</span></div>
              <Select options={states} styles={{indicatorSeparator: () => ({display: 'none'})}} placeholder='Select a State' onChange={e => setFieldValue('state', e.value)} />
              {errors.city && touched.city && <span className='request-error'>{errors.city}</span>}
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>Zip Code <span>*</span></div>
              <input value={values.zipcode} name='zipcode' onChange={handleChange} className="request-form-input" />
              {errors.zipcode && touched.zipcode && <span className='request-error'>{errors.zipcode}</span>}
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>Email</div>
              <input value={values.email} name='email' type="email" onChange={handleChange} className="request-form-input" />
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>Home Phone</div>
              <input value={values.phone} name='phone' onChange={handleChange} className="request-form-input" />
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>Interests</div>
              {interestCheck.map((i, index) => (
              <div className='check-item' key={i.label}>
                  <input type='checkbox' checked={i.checked} onChange={e => {
                    setInterestCheck(p => ([...p.slice(0, index), {checked: e.target.checked, label: i.label}, ...p.slice(index + 1)]));
                    let temp = values.interests.slice();
                    if (e.target.checked) {
                      temp.push(i.label);
                    } else {
                      const itemInd = temp.findIndex(i.label);
                      temp = [...temp.slice(0, itemInd), ...temp.slice(itemInd + 1)];
                    }
                    setFieldValue('interests', temp);
                  }} />
                  <span className='check-label'>{i.label}</span>
                </div>
              ))}
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>How did you hear about Kencove <span>*</span></div>
              <Select options={locations} styles={{indicatorSeparator: () => ({display: 'none'})}} placeholder='' onChange={e => setFieldValue('how', e.value)} />
              {errors.how && touched.how && <span className='request-error'>{errors.how}</span>}
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>Which one (please specify) <span>*</span></div>
              <input value={values.which} name='which' onChange={handleChange} className="request-form-input" />
              {errors.which && touched.which && <span className='request-error'>{errors.which}</span>}
            </div>
            <div className='request-item'>
              <div className='request-form-input-label'>Comments/Additional product information</div>
              <textarea value={values.comments} name='comments' onChange={handleChange} className="request-form-input" />
              {errors.comments && touched.comments && <span className='request-error'>{errors.comments}</span>}
            </div>
            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} className="request-item" />
            <button type='submit' className='request-submit'>Submit</button>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default App;
